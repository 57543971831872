console.log('WE LOVE TYPO3');


var $slider = $('.slider');
var $progressBar = $('.progress');
var $progressBarLabel = $('.slider__label');
var calc;

var bLazy = new Blazy({
  // options
});

$slider.on('init', function (event, slick, currentSlide, nextSlide) {

  calc = 100 / (slick.slideCount);

  console.log(calc)


  $progressBar
    .css('background-size', calc + '% 100%')
    .attr('aria-valuenow', calc);

  $progressBarLabel.text(calc + '% completed');
});



var speed = $('.imageLoop').data('speed');
var autoplay = false;
var arrow = true;
var draggable = true;
var speed = 1000;

if ($('.headerSlider').hasClass('autoplay')) {
  autoplay = true;
  arrow = false;
  fade = true;
  draggable = false;
  speed = 0;
}


$('.headerSlider').slick({
  infinite: true,
  slidesToShow: 1,
  autoplay: autoplay,
  autoplaySpeed: 5000,
  speed: speed,
  fade: true,
  pauseOnHover: false,
  pauseOnFocus: false,
  draggable: draggable,
  arrows: arrow,

});

$('.slider').slick({
  infinite: true,
  slidesToShow: 1,
  autoplay: true,
  autoplaySpeed: 4000,
  fade: false,
  pauseOnHover: false,
  pauseOnFocus: false,
  draggable: draggable,
  arrows: false,

});


$(document).ready(function () {
  $('.navTrigger').click(function () {
    $('body').toggleClass('navActive');
  });

  var controller_fade = new ScrollMagic.Controller();

  $('.sliderWrap').each(function () {
    var $this = $(this);

    var scene = new ScrollMagic.Scene({
      triggerElement: this,
      offset: 20,
      duration: $this.height(),
      triggerHook: 0.05
    })
      .setClassToggle($('body'), 'nav-white')
      .addTo(controller_fade);
  });

  $(function () {
    if (!$(".sliderWrap").length) {
      console.log("2");
      $('body').addClass('padding-body');

    }
  });


});



$slider.on('beforeChange', function (event, slick, currentSlide, nextSlide) {
  calc = ((nextSlide + 1) / (slick.slideCount)) * 100;
  $progressBar
    .css('background-size', calc + '% 100%')
    .attr('aria-valuenow', calc);

  $progressBarLabel.text(calc + '% completed');
});


